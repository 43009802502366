(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-draws.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-draws.js');
"use strict";

const _excluded = ["favouriteOdds", "odds", "startOdds", "svenskaFolket", "svenskaFolketDate", "svenskaFolketRefDate", "tioTidningarsTips", "match", "eventTypeId", "providerIds", "betMetrics", "complementaryBetMetrics"],
  _excluded2 = ["league", "participants", "matchId"],
  _excluded3 = ["country"],
  _excluded4 = ["sport", "drawEvents", "events"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
let tipsenShared;
let tipsenSharedConstants;
let productIds;
let sportInfoConstants;
let sportInfoHelpers;
let SportTypes;
if (svs.isServer) {
  const trinidad = require('trinidad-core');
  tipsenShared = require('../../../tipsen-shared/assets/javascripts/tipsen-shared.es6');
  tipsenSharedConstants = require('../../../tipsen-shared/assets/javascripts/constants.es6');
  const products = trinidad.core.components.require('utils', 'products');
  productIds = products.productIds;
  sportInfoConstants = require('../../../../sportinfo/common/assets/javascripts/constants.es6');
  sportInfoHelpers = require('../../../../sportinfo/common/assets/javascripts/helpers.es6');
  SportTypes = require('trinidad-core').core.components.require('sport', 'sport-types').api.SportTypes;
} else {
  tipsenShared = svs.components.sport.tipsenShared;
  tipsenSharedConstants = svs.components.sport.tipsenShared.constants;
  productIds = svs.utils.products.productIds;
  sportInfoConstants = svs.components.sportinfo.common.constants;
  sportInfoHelpers = svs.components.sportinfo.common.helpers;
  SportTypes = svs.components.sport.sportTypes.SportTypes;
}
const {
  EventTypeId,
  EventTypeField,
  EventTypeFieldType,
  EventTypeDistributionsFallback,
  AllKnownOutcomes,
  ExternalIdProviders: Providers
} = sportInfoConstants;
const {
  createEventStatisticsKey,
  createBetEventKey,
  normalizeEventTypeField,
  normalizeOddsItem
} = sportInfoHelpers;
const {
  getOutcomeCount,
  userProduct2SubProductId
} = tipsenShared;
const {
  ParticipantTypes
} = tipsenSharedConstants;

const getFallbackSport = _ref => {
  let {
    productId,
    matchCount,
    eventCount
  } = _ref;
  if (productId === productIds.BOMBEN && matchCount === 1 && eventCount > 1) {
    return {
      type: SportTypes.Hockey,
      name: 'Hockey'
    };
  }
  return {
    type: SportTypes.Undefined,
    name: 'Undefined'
  };
};
const KnownOutcomes = {
  [EventTypeId.EVENT_1X2]: ['1', 'X', '2'],
  [EventTypeId.GOAL_COUNT]: ['0', '1', '2', '3', '4', '5', '6', '7'],
  [EventTypeId.RESULT]: {
    [productIds.BOMBEN]: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    [productIds.MATCHEN]: [...Array(56).keys()]
  }
};
const getKnownOutcomes = (eventTypeId, productId) => {
  if (eventTypeId === EventTypeId.RESULT) {
    return KnownOutcomes[eventTypeId][productId];
  }
  return KnownOutcomes[eventTypeId];
};
const isEmptyObject = obj => Object.values(obj).every(val => !val);
const formatValue = v => v ? "".concat(v).replace(',', '.') : null;
const convertComplementaryBetMetricsValuesToMatchSportInfoValues = (eventTypeField, values) => {
  const convertedValues = [];
  if (values && eventTypeField) {
    values.forEach(value => {
      convertedValues.push({
        outcome: value.outcome,
        value: value.odds[eventTypeField]
      });
    });
  }
  return convertedValues;
};
const normalizeDraws = draws => {
  const Draws = {
    ids: [],
    entities: {}
  };
  const BetEvents = {};
  const SportEvents = {};
  const Leagues = {};
  const Sports = {};
  const Participants = {};
  const Countries = {};
  const EventTypeStatistic = {};
  const generateDrawKey = draw => "".concat(draw.productId, "_").concat(draw.drawNumber);
  const normalizeEventTypeId = _ref2 => {
    let {
      productId,
      participantType = 'Team',
      eventTypeId
    } = _ref2;
    const isEventTypeIdH2H = productIds.POWERPLAY === productId && participantType === ParticipantTypes.Player;
    if (isEventTypeIdH2H) {
      return EventTypeId.HEAD_TO_HEAD;
    }
    if ([productIds.BOMBEN, productIds.MATCHEN].includes(productId)) {
      return EventTypeId.RESULT;
    }
    return eventTypeId;
  };

  const parseParticipant = (participant, leagueCountry) => {
    const participantCountry = {
      id: participant.countryId,
      name: participant.countryName,
      isoCode: participant.isoCode
    };
    participant.countryId = leagueCountry === null || leagueCountry === void 0 ? void 0 : leagueCountry.id;
    if (!isEmptyObject(participantCountry)) {
      Countries[participantCountry.id] = participantCountry;
      participant.countryId = participantCountry.id;
    }
    Participants[participant.id] = participant;
  };

  const parseDrawEvent = (realDrawEvent, draw, sport) => {
    const {
        favouriteOdds,
        odds,
        startOdds,
        svenskaFolket,
        svenskaFolketDate,
        svenskaFolketRefDate,
        tioTidningarsTips,
        match: realMatch,
        eventTypeId: realEventTypeId,
        providerIds = [],
        betMetrics,
        complementaryBetMetrics
      } = realDrawEvent,
      drawEvent = _objectWithoutProperties(realDrawEvent, _excluded);
    let matchId;
    const betEventKey = createBetEventKey(draw.productId, draw.drawNumber, drawEvent.eventNumber);

    const normalizedEventTypeId = normalizeEventTypeId({
      productId: draw.productId,
      participantType: drawEvent.participantType,
      eventTypeId: realEventTypeId
    });
    if (!realMatch) {
      matchId = betEventKey;
      let participants;
      if (draw.matchCount === 1) {
        const homeNameFallback = 'Hemmalag';
        const homeParticipantFallback = {
          name: homeNameFallback,
          mediumName: homeNameFallback,
          id: "".concat(matchId, "_", 0)
        };
        const awayNameFallback = 'Bortalag';
        const awayParticipantFallback = {
          name: awayNameFallback,
          mediumName: awayNameFallback,
          id: "".concat(matchId, "_", 1)
        };
        participants = [homeParticipantFallback, awayParticipantFallback];
      } else {
        participants = drawEvent.eventDescription.split('-').map((participant, i) => {
          const name = participant.trim();
          return {
            name,
            mediumName: name,
            id: "".concat(matchId, "_").concat(i)
          };
        }).filter(_ref3 => {
          let {
            name
          } = _ref3;
          return name;
        }).slice(0, 2);
      }
      const match = {
        matchDataMissingFromBackend: true,
        matchId,
        placeholder: true,
        participants: participants.map(participant => participant.id),
        eventTypeId: normalizeEventTypeId || realEventTypeId
      };
      for (const participant of participants) {
        Participants[participant.id] = participant;
      }
      BetEvents[betEventKey] = match;
    } else {
      const {
          league: realLeague,
          participants,
          matchId: realMatchId
        } = realMatch,
        sportEvent = _objectWithoutProperties(realMatch, _excluded2);
      delete sportEvent.statusId;
      delete sportEvent.status;
      sportEvent.externalId = providerIds.filter(_ref4 => {
        let {
          provider
        } = _ref4;
        return Providers[provider];
      }).reduce((acc, _ref5) => {
        let {
          provider,
          id
        } = _ref5;
        return _objectSpread(_objectSpread({}, acc), {}, {
          [Providers[provider]]: id
        });
      }, {});
      matchId = realMatchId;
      const betEvent = _objectSpread({
        matchId,
        eventDescription: drawEvent.eventDescription,
        eventNumber: drawEvent.eventNumber,
        eventTypeId: normalizedEventTypeId
      }, (drawEvent.eventComment || drawEvent.comment) && {
        eventComment: drawEvent.eventComment || drawEvent.comment
      });
      if (drawEvent.comment) {
        delete drawEvent.comment;
      }

      if (draw.productId === productIds.BOMBEN && draw.matchCount === 1) {
        betEvent.periodNumber = drawEvent.eventNumber;
      } else {
        betEvent.periodNumber = 0;
      }
      if (realMatch.mutuals) {
        betEvent.mutuals = realMatch.mutuals;
      }
      const _ref6 = realLeague || {},
        {
          country: leagueCountry
        } = _ref6,
        league = _objectWithoutProperties(_ref6, _excluded3);
      if (leagueCountry) {
        Countries[leagueCountry.id] = leagueCountry;
      }
      const safeParticipants = participants || [];

      for (const participant of safeParticipants) {
        parseParticipant(participant, leagueCountry);
      }
      betEvent.participantType = drawEvent.participantType;
      betEvent.participants = safeParticipants.map(participant => participant.id);
      sportEvent.participantType = drawEvent.participantType;
      sportEvent.participants = safeParticipants.map(participant => participant.id);
      sportEvent.dataState = tipsenShared.ReducerState.Done;

      const leagueId = league === null || league === void 0 ? void 0 : league.id;
      betEvent.leagueId = leagueId;
      if (leagueId) {
        var _leagueCountry$id;
        league.sportType = sport.type;
        league.countryId = (_leagueCountry$id = leagueCountry === null || leagueCountry === void 0 ? void 0 : leagueCountry.id) !== null && _leagueCountry$id !== void 0 ? _leagueCountry$id : null;
        Leagues[leagueId] = league;
      }
      betEvent.externalId = providerIds.filter(_ref7 => {
        let {
          provider
        } = _ref7;
        return Providers[provider];
      }).reduce((acc, _ref8) => {
        let {
          provider,
          id
        } = _ref8;
        return _objectSpread(_objectSpread({}, acc), {}, {
          [Providers[provider]]: id
        });
      }, {});
      if (!SportEvents[matchId]) {
        sportEvent.matchId = matchId;
        sportEvent.leagueId = leagueId;
        SportEvents[matchId] = sportEvent;
      }

      const eventTypeStatisticId = createEventStatisticsKey({
        matchId,
        eventTypeId: betEvent.eventTypeId,
        participantIds: betEvent.participants,
        periodNumber: betEvent.periodNumber
      });
      const _eventTypeId = (betMetrics === null || betMetrics === void 0 ? void 0 : betMetrics.eventTypeId) || realEventTypeId || normalizedEventTypeId;
      const currentDistributionDate = (betMetrics === null || betMetrics === void 0 ? void 0 : betMetrics.distributionDate) || svenskaFolketDate;
      const previousDistributionDate = (betMetrics === null || betMetrics === void 0 ? void 0 : betMetrics.distributionRefDate) || svenskaFolketRefDate;
      const _productId = draw.productId;
      const arrayOutcomes = getKnownOutcomes(_eventTypeId, _productId);
      const _sportType = sport.type;
      const outcomeCount = getOutcomeCount(_eventTypeId, _sportType, _productId);
      const arrayAllKnownOutcomes = AllKnownOutcomes[_eventTypeId];
      const currentOdds = new Array(outcomeCount).fill(null);
      const currentStartOdds = new Array(outcomeCount).fill(null);
      const currentFavourites = new Array(outcomeCount).fill(null);
      const currentDistribution = new Array(outcomeCount).fill(null);
      const previousDistribution = new Array(outcomeCount).fill(null);
      const currentTioTidningar = new Array(outcomeCount).fill(null);
      if (_productId === productIds.BOMBEN || _productId === productIds.MATCHEN) {
        if (Array.isArray(svenskaFolket) && svenskaFolket.length === arrayOutcomes.length) {
          for (let i = 0; i < arrayOutcomes.length; i++) {
            var _svenskaFolket$i, _svenskaFolket$i2, _svenskaFolket$i3, _svenskaFolket$i4;
            currentDistribution[i] = [formatValue((_svenskaFolket$i = svenskaFolket[i]) === null || _svenskaFolket$i === void 0 ? void 0 : _svenskaFolket$i.home), formatValue((_svenskaFolket$i2 = svenskaFolket[i]) === null || _svenskaFolket$i2 === void 0 ? void 0 : _svenskaFolket$i2.away)];
            previousDistribution[i] = [formatValue((_svenskaFolket$i3 = svenskaFolket[i]) === null || _svenskaFolket$i3 === void 0 ? void 0 : _svenskaFolket$i3.refHome), formatValue((_svenskaFolket$i4 = svenskaFolket[i]) === null || _svenskaFolket$i4 === void 0 ? void 0 : _svenskaFolket$i4.refAway)];
          }
        }
      } else {
        arrayOutcomes.forEach((outcome, i) => {
          let odds = null;
          let startOdds = null;
          let favourites = null;
          let distribution = null;
          let refDistribution = null;
          let tioTidningar = null;
          const value = betMetrics === null || betMetrics === void 0 ? void 0 : betMetrics.values.find(el => el.outcome === outcome);
          if (value) {
            var _value$odds, _value$odds2, _value$odds3, _value$distribution, _value$distribution2;
            odds = formatValue((_value$odds = value.odds) === null || _value$odds === void 0 ? void 0 : _value$odds.odds);
            startOdds = formatValue((_value$odds2 = value.odds) === null || _value$odds2 === void 0 ? void 0 : _value$odds2.startOdds);
            favourites = formatValue((_value$odds3 = value.odds) === null || _value$odds3 === void 0 ? void 0 : _value$odds3.favouriteOdds);
            distribution = formatValue((_value$distribution = value.distribution) === null || _value$distribution === void 0 ? void 0 : _value$distribution.distribution);
            refDistribution = formatValue((_value$distribution2 = value.distribution) === null || _value$distribution2 === void 0 ? void 0 : _value$distribution2.refDistribution);
          }
          if (tioTidningarsTips) {
            tioTidningar = formatValue(tioTidningarsTips[arrayAllKnownOutcomes[i]]);
          }
          currentOdds[i] = odds;
          currentStartOdds[i] = startOdds;
          currentFavourites[i] = favourites;
          currentDistribution[i] = distribution;
          previousDistribution[i] = refDistribution;
          currentTioTidningar[i] = tioTidningar;
        });
      }
      const _eventTypeStatistic = EventTypeStatistic[eventTypeStatisticId] || {};
      const _eventTypeStatisticOdds = _eventTypeStatistic[EventTypeField.Odds] || {};
      const _eventTypeStatisticStartOdds = _eventTypeStatistic[EventTypeField.StartOdds] || {};
      const _eventTypeStatisticFavourites = _eventTypeStatistic[EventTypeField.Favourites] || {};
      const _eventTypeStatisticDistributions = _eventTypeStatistic[EventTypeField.Distributions] || {};
      const _eventTypeStatisticTioTidnigarTips = _eventTypeStatistic[EventTypeField.TioTidnigarTips] || {};
      EventTypeStatistic[eventTypeStatisticId] = _objectSpread(_objectSpread({}, _eventTypeStatistic), {}, {
        [EventTypeField.Odds]: _objectSpread(_objectSpread({}, _eventTypeStatisticOdds), {}, {
          current: {
            value: currentOdds
          },
          valueType: EventTypeFieldType.Odds
        }),
        [EventTypeField.StartOdds]: _objectSpread(_objectSpread({}, _eventTypeStatisticStartOdds), {}, {
          current: {
            value: currentStartOdds
          },
          valueType: EventTypeFieldType.Odds
        }),
        [EventTypeField.Favourites]: _objectSpread(_objectSpread({}, _eventTypeStatisticFavourites), {}, {
          current: {
            value: currentFavourites
          },
          valueType: EventTypeFieldType.Percentage
        }),
        [EventTypeField.Distributions]: _objectSpread(_objectSpread({}, _eventTypeStatisticDistributions), {}, {
          [draw.productId]: _objectSpread(_objectSpread({}, _eventTypeStatisticDistributions[draw.productId]), {}, {
            [EventTypeDistributionsFallback]: {
              current: {
                value: currentDistribution,
                date: currentDistributionDate
              },
              previous: {
                value: previousDistribution,
                date: previousDistributionDate
              },
              valueType: EventTypeFieldType.Percentage
            },
            [draw.drawNumber]: {
              current: {
                value: currentDistribution,
                date: currentDistributionDate
              },
              previous: {
                value: previousDistribution,
                date: previousDistributionDate
              },
              valueType: EventTypeFieldType.Percentage
            }
          })
        }),
        [EventTypeField.TioTidnigarTips]: _objectSpread(_objectSpread({}, _eventTypeStatisticTioTidnigarTips), {}, {
          current: {
            value: currentTioTidningar
          },
          valueType: EventTypeFieldType.Count
        })
      });
      if (complementaryBetMetrics && complementaryBetMetrics.length > 0) {
        complementaryBetMetrics.forEach(_ref9 => {
          let {
            eventTypeId,
            values
          } = _ref9;
          const eventStatisticsKey = createEventStatisticsKey({
            matchId,
            eventTypeId,
            participantIds: betEvent.participants,
            periodNumber: betEvent.periodNumber
          });
          const overUnderOdds = convertComplementaryBetMetricsValuesToMatchSportInfoValues(EventTypeField.Odds, values);
          if (overUnderOdds && overUnderOdds.length > 0) {
            const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.Odds, normalizeOddsItem(overUnderOdds, eventTypeId));
            EventTypeStatistic[eventStatisticsKey] = _objectSpread(_objectSpread({}, EventTypeStatistic[eventStatisticsKey]), newOdds);
          }
          const favourites = convertComplementaryBetMetricsValuesToMatchSportInfoValues(EventTypeField.FavouriteOdds, values);
          if (favourites && favourites.length > 0) {
            const newOdds = normalizeEventTypeField(eventTypeId, EventTypeField.Favourites, normalizeOddsItem(favourites, eventTypeId));
            EventTypeStatistic[eventStatisticsKey] = _objectSpread(_objectSpread({}, EventTypeStatistic[eventStatisticsKey]), newOdds);
          }
        });
      }
      betEvent.eventTypeStatisticId = eventTypeStatisticId;
      sportEvent.eventTypeStatisticId = eventTypeStatisticId;

      BetEvents[betEventKey] = betEvent;
    }
    drawEvent.matchId = matchId;
    drawEvent.eventTypeId = normalizedEventTypeId;
    draw.drawEvents.push(drawEvent);
  };

  const parseDraw = realDraw => {
    const {
        sport,
        drawEvents,
        events
      } = realDraw,
      draw = _objectWithoutProperties(realDraw, _excluded4);
    const drawKey = generateDrawKey(draw);
    delete draw.fund;
    Draws.ids.push(drawKey);
    Draws.entities[drawKey] = draw;
    draw.subProductId = userProduct2SubProductId[draw.userProduct];
    delete draw.userProduct;
    draw.drawEvents = [];
    if (realDraw.enabled && !realDraw.regBetDisabled) {
      draw.regBetDisabled = tipsenShared.DisableType.NotDisabled;
      delete draw.enabled;
    }
    if (realDraw.currentNetSales) {
      draw.currentNetSale = realDraw.currentNetSales;
    }
    if (sport) {
      const sportIsUndefined = sport.type === SportTypes.Undefined;
      const normalizedSport = sportIsUndefined ? _objectSpread(_objectSpread({}, sport), getFallbackSport(draw)) : sport;
      draw.sportType = normalizedSport.type;
      Sports[normalizedSport.type] = normalizedSport;
    }
    const realDrawEvents = drawEvents || events;
    for (const realDrawEvent of realDrawEvents) {
      parseDrawEvent(realDrawEvent, draw, sport);
    }
  };

  if (draws) {
    for (const draw of draws) {
      parseDraw(draw);
    }
  }
  return {
    Draws,
    BetEvents,
    SportEvents,
    Leagues,
    Sports,
    Participants,
    Countries,
    EventTypeStatistic
  };
};
if (svs.isServer) {
  module.exports = normalizeDraws;
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeDraws', normalizeDraws);
}

 })(window);